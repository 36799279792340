import { ToastOptions } from "react-toastify";

export let domain;

if (process.env.REACT_APP_API_URL) {
  domain = process.env.REACT_APP_API_URL;
} else if (process.env.NODE_ENV === "production") {
  domain = "https://app.riivet.com/app";
} else {
  domain = "https://app.riivet.com/app";
  //domain = "https://dbapp.riivet.com";
  //domain = `http://localhost:3003`;
}
// staging domain
// domain = "https://staging-app.riivet.com";

// export domain;
export const prodURL = "https://app.riivet.com/app";
export const stagingURL = "https://staging-app.riivet.com";

export const baseUrl = `${domain}/api/v1`;
export const authUrl = `${domain}/api/auth`;
export const analyticsUrl = `${domain}/api/analytics/v1`;
export const financialUrl = `${domain}/api/financial/v1`;
export const marketingUrl = `${domain}/api/marketing/v1`;
export const accountUrl = `${domain}/api/account/v1`;
export const adminUrl = `${domain}/api/admin`;

export const tusEndpoint =
  process.env.NODE_ENV === "production"
    ? "https://video.riivet.com/tus/files"
    : "http://localhost:3005/files";

export const OWNER_ROLE = "manage";
export const GREY_TEXT = "#495057";
export const INFO_COLOR = "#4169E1";
export const ERROR_COLOR = "#ff0000";

export const toastOptions: ToastOptions<unknown> | undefined = {
  position: "bottom-right",
  pauseOnHover: true,
  hideProgressBar: true,
  theme: "colored",
  draggable: false,
  closeOnClick: false,
};

export const DEFAULT_IMAGES = {
  SMALL: "https://riivet.s3.amazonaws.com/missing/account_picture_small.png",
  MEDIUM: "https://riivet.s3.amazonaws.com/missing/account_picture_medium.png"
}
